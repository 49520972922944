"use client";
import { checkResponse } from "./check-response";
import { checkSuccess } from "./check-success";
import { getCookie } from "./cookie";

export async function requestClient(url, options) {
  const choosedCity = getCookie("choosedCity")
    ? JSON.parse(getCookie("choosedCity")).code
    : "moskva";
  const requestUrl =
    url + (url.includes("?") ? "&" : "?") + "choosedCity=" + choosedCity;
  return await fetch(requestUrl, {
    ...options,
    headers: {
      ...options?.headers,
      // Cookie: "wsr_nocache=1",
      Authorization:
        options?.headers?.Authorization || getCookie("accessToken"),
    },
    next: {
      revalidate:
        options?.next && typeof options?.next.revalidate === "number"
          ? options.next.revalidate
          : 2 * 60,
    },
  })
    .then((res) => checkResponse(res, requestUrl))
    .then(checkSuccess);
}
