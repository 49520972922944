import { Toast } from "@/components/toast/toast";
import { toast } from "react-toastify";

export const callMessage = (
  title: string,
  subtitle: string,
  status: boolean
) => {
  const toastElement = <Toast message={title} submessage={subtitle} />;
  return status ? toast.success(toastElement) : toast.error(toastElement);
};
